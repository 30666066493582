import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/(assessment)/assessments": [~5],
		"/(assessment)/assessment/[[id]]": [~4],
		"/(infectious)/infectiouses": [~7],
		"/(infectious)/infectious/[[id]]": [~6],
		"/login": [~14],
		"/logout": [15],
		"/(noninfectious)/noninfectiouses": [~9],
		"/(noninfectious)/noninfectious/[[id]]": [~8],
		"/(patient)/patients": [~11],
		"/(patient)/patient/[[id]]": [~10,[],[2]],
		"/(pd)/pds": [~13],
		"/(pd)/pd/[[id]]": [~12],
		"/xlsx": [~16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';